<template>
  <div class="pages p20">
    <div class="title-btn-block">
      <div class="title-block">
        {{ $t("message.ask_vacation") }}: 
        <small v-if="staff&&staff.last_vacation_managment">({{$t('message.accept_vacation_managment')}}: {{formatMonthDate(staff.last_vacation_managment.month)}} )</small>
        <small v-else>({{$t('message.accept_vacation_managment')}}: {{$t('message.not_attached')}})</small>
      
      </div>
      <div>
        <el-button class="my-btn " type="warning" @click="take" round v-if="staff&&staff.last_vacation_managment">
          {{ $t("message.ask_vacation") }}
        </el-button>
        <router-link :to="{ name: 'my_vacations' }">
          <div class="my-btn cr1 el-button ml20">
            <i class="el-icon-d-arrow-left"></i> {{$t('message.back')}}
          </div>
        </router-link>
       
      </div>
    </div>
    <div class="content-block" v-if="staff&&staff.last_vacation_managment">
      <div class="datetime-start bg p20 mb20">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <el-divider content-position="left">{{$t('message.reason')}}</el-divider>
          </el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <el-divider content-position="left"> {{$t('message.left_date')}}</el-divider>
          </el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <el-divider content-position="left">{{$t('message.come_date')}}</el-divider>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="seloction mb20">
              <el-select
                v-model="form.reason_id"
                :placeholder="$t('message.reason')"
                class="w-100"
                popper-class="my-data-khan"
              >
                <el-option
                  v-for="(reason, index) in reasons"
                  :key="'guestreason-' + index"
                  :label="reason.name"
                  :value="reason.id"
                ></el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="datetime-start-time mb20">
              <el-date-picker
                class="w-100"
                v-model="form.left_date"
                type="date"
                :picker-options="dateRangeOptions1"
                :placeholder="$t('message.left_date')"
                popper-class="my-data-khan"
              >
              </el-date-picker>
            </div>
          </el-col>

          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="datetime-start-time mb20">
              <el-date-picker
                class="w-100"
                v-model="form.coming_date"
                type="date"
                :placeholder="$t('message.come_date') "
                :picker-options="dateRangeOptions1"
                popper-class="my-data-khan"
                
              >
              </el-date-picker>
            </div>
          </el-col>
         
        </el-row>
      </div>
      <div class="comment bg p20 mb20">
        <el-divider content-position="left"> {{$t('message.comment')}} </el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-input type="textarea" v-model="form.comment"></el-input>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-else>
      <div class="comment bg p20 mb20">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <h2 class="title-block " style="text-align: center;color:#afafaf;">Sizga ta'til oyi biriktirilmagan!</h2>
            <p style="text-align: center; color:#afafaf; font-size:14px"><b>Bu muammoni HR (xodim) bilan bog'lanib hal qilishingiz mumkin!!!</b></p>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { getItem } from "@/utils/storage";
import moment from 'moment';
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      region: "",
      value1: "",
      value: "",
      form: {},
      staff:null,
      disable_date: true,
      disable_time: true,
      dateRangeOptions1: {
        firstDayOfWeek: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "vacation/list",
      reasons: "vacation/reasons",
    }),
  },
  mounted() {
    this.getUser();
    this.reasonsAction();
  },
  methods: {
    ...mapActions({
      reasonsAction: "vacation/reasons",
      updateList: "vacation/store",
      getStaff: "assent/staffInfo",
    }),
    date(val) {
      if (val == "date") {
        (this.disable_date = false), (this.disable_time = false);
      }
    },
    getUser() {      
      this.getStaff(getItem('userId')).then((res) => {
         if (res.status==200) { 
           this.staff = res.data.result.data.staff;
        }
        else{
           let route = this.$router.push({
              name: "login",
            });
        }
      });
    },
    take() {
      this.form.staff_id = getItem("userId");
      this.form.left_date = moment(this.form.left_date).format('YYYY-MM-DD');
      this.form.coming_date = moment(this.form.coming_date).format('YYYY-MM-DD');       
      this.updateList(this.form)
        .then((res) => {
          if (res.status == 201) {
            this.$alert(res);
            let route = this.$router.push({
              name: "Home",
              params: {
                id: this.list.id,
              },
            });
            window.open(route.href, "_self");
          } else {
            this.$alert(res);
          }
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
    formatMonthDate(value){
          if (value) {
            return moment((value)).lang("ru").format('MMMM YYYY')
          }
    }
  },
};
</script>
